// get current full URL with parameters
const urlQueryString = window.location.search;
//console.log("queryString: " + urlQueryString);

// get current URL parameteres
const urlParams = new URLSearchParams(urlQueryString);


//check if a crm guid is present via google campaign link and put it in hidden field
if ( urlParams.has("glcid") ) {
  const crmGuid = "googleAd";
  document.getElementById("g").value = crmGuid;
} else if ( urlParams.has("utm_source") ) {
  const crmGuid = urlParams.get("utm_source");
  document.getElementById("g").value = crmGuid;  
} else if ( urlParams.has("itm_source") ) {
  const crmGuid = urlParams.get("itm_source");
  document.getElementById("g").value = crmGuid;  
}